import React, { Component } from "react"
import ReleaseListContent from "./releaseListContent"

class ReleaseListContainer extends Component {

  componentDidMount() {

  }

  render() {
    let searchQuery, errorMessage
    let error = false
    
    if (this.props.searchQuery) {
      searchQuery = this.props.searchQuery
    } else {
      searchQuery = "Search"
    }

    if (this.props.errorMessage) {
      error = true
      errorMessage = this.props.errorMessage
    }

    return (
      <>
        <ReleaseListContent
          title={this.props.title}
          releases={this.props.releases}
          readyToLoad={this.props.readyToLoad}
          releaseType={this.props.releaseType}
          searchQuery={searchQuery}
          error={error}
          errorMessage={errorMessage}
        />
      </>
    )
  }
}
export default ReleaseListContainer