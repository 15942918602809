import React, { Component } from "react"
import { Scene } from 'react-scrollmagic'
import { Link } from "gatsby"
import slugify from "../../utils/slugify"
import SkeletonText from "../skeleton"
import OnImagesLoaded  from 'react-on-images-loaded'
import DefaultImg from "../defaultImg"
import { globalHistory } from "@reach/router"
const path = globalHistory.location.pathname

class ReleaseListItem extends Component {
  state = { 
    imgLoaded: false
  }

  yearColumn( year ) {
    if ( this.props.releaseType === "contentItem" ) {
      return("")
    } else {
      return (
        <div className="p-2 pl-0 md:pl-2 lg:pl-2 xl:pl-2 w-3/12 md:w-1/12 lg:w-1/12 xl:w-1/12 text-left">
          {year}
        </div>
      )
    }
  }

  render() {
    const { location } = this.props;
    let catNum, artist, title, url, imgSrc, year, key, date
    if ( this.props.readyToLoad ) {

      if ( this.props.releaseType == "contentItem") {
        const release = this.props.release
        artist = release.reference.resource.artist.name
        title = release.title
        url = `/${slugify(release.reference.resource.artist.name)}/${slugify(release.title)}`
        imgSrc = release.imageUrl
        catNum = release.reference.resource.catNumber
        key=release.reference.id
      } else {
        const release = this.props.release
        artist = release.artist.name
        title = release.title
        url = `/${slugify(release.artist.name)}/${slugify(release.title)}`
        imgSrc = release.imageUrl
        catNum = release.catNumber
        key=release.id
        date=new Date(release.releaseDate)
        year=date.getFullYear()
      }

    } else {
      artist = <SkeletonText />
      title = <SkeletonText />
      url = `/`
      imgSrc = "../../defaultImg.svg"
      catNum = <SkeletonText />
      year = <SkeletonText />  
      key=""
    }

    let itemCx;
    const height = 96;

    itemCx = "listEl relative w-full text-gray-700 cursor-pointer hover:text-black z-0 hover:z-10 uppercase border-b text-xs md:text-base lg:text-base xl:text-base px-4 md:px-6 lg:px-6 xl:px-6" 

    // Img animation
    let imgAnim
    let imgAnimDefault = {
      transitionProperty: 'all',
      transitionDuration: '.1s', 
      transitionDelay: '.075s',
    }

    if (this.state.imgLoaded) {
      imgAnim = {
        opacity: 1,
        ...imgAnimDefault
      }
    } else {
      imgAnim = {
        opacity: 0,
        ...imgAnimDefault
      }
    }

    return (
        <Link className={itemCx} to={url} key={key}>
          <div className="w-full flex justify-center items-center">
            <div className="tab absolute h-full left-0 bg-k7green" style={{width: '12px'}}></div>
            <div className="hidden md:block lg:block xl:block pl-2 w-1/12 mono text-xs">
              {catNum}
            </div>
            <div className="ml-4 md:ml-0 lg:ml-0 xl:ml-0 p-2 w-3/12 text-center h-full">
              <OnImagesLoaded onLoaded={() => this.setState({ imgLoaded: true })}>
                <div className="img inline-block relative">
                  <div className="absolute top-0 left-0">
                    <DefaultImg className="w-16 md:w-24 lg:w-24 xl:w-24"/>
                  </div>
                  <img className="z-1 relative w-16 md:w-24 lg:w-24 xl:w-24 inline-block" style={imgAnim} src={imgSrc} />
                </div>
              </OnImagesLoaded>
            </div>
            <div className="flex-grow p-2 pr-0 md:pr-2 lg:pr-2 xl:pr-2 w-7/12 md:flex lg:flex xl:flex">
              <div className="flex-grow w-full d:w-1/2 lg:w-1/2 xl:w-1/2 pr-4">
                {title}
              </div >
              <div className="flex-grow w-full md:w-1/2 lg:w-1/2 xl:w-1/2 sm:gray-500">
                {artist}
              </div>   
            </div>

            {this.yearColumn( year )}

          </div>

        </Link>

    )
  }
}
export default ReleaseListItem



