import React, { Component } from "react"

class SkeletonText extends Component {
  state = {
  }

  render() {

    return (
      <div className="w-full border block" style={{color: '#E6E6E6', background: '#E6E6E6'}}>LOADING</div>
    )

  }
}

export default SkeletonText
