import React, { Component } from "react"
import { navigate } from "gatsby"
import { Controller, Scene } from 'react-scrollmagic'
import ReleaseListItem from './releaseListItem'
import { globalHistory } from "@reach/router"
import { Link } from "gatsby"

import iconSearch from "../../../static/search.svg"
import upSort from "../../../static/upSort.svg"
import downSort from "../../../static/downSort.svg"

const path = globalHistory.location.pathname
let postsToShow = 20;



class ReleaseListContent extends Component {
  
  state = {
    showingMore: postsToShow > 20,
    postsToShow,
    orderIsChronological: true,
    search: '',
  }

  update() {
    const distanceToBottom =
      document.documentElement.offsetHeight -
      (window.scrollY + window.innerHeight)
    if (distanceToBottom < 100) {
      this.setState({ postsToShow: this.state.postsToShow + 20 })
    }
    this.ticking = false
  }

  handleScroll = () => {
    if (!this.ticking) {
      this.ticking = true
      requestAnimationFrame(() => this.update())
    }
  }

  componentDidMount() {
    window.addEventListener(`scroll`, this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener(`scroll`, this.handleScroll)
    window.postsToShow = this.state.postsToShow
  }

  toggleOrder() {
    this.setState({orderIsChronological: !this.state.orderIsChronological})
  }

  yearColumn(iconSrc) {
    if ( this.props.releaseType === "contentItem" ) {
      return ("")
    } else {
      return (
        <div onClick={() => { this.toggleOrder() }}  className="cursor-pointer hover:opacity-50 p-1 px-2 w-3/12 md:w-1/12 xl:w-1/12 lg:w-1/12">
          Year <img width="9px" className="inline-block" src={iconSrc}/>
        </div>  
      )
    }
  }

  djKicksHistoryLink() {
    if ( this.props.title == "DJ-Kicks") {
      return (
        <Link 
          to="/djkicks-history"
          className="float-right uppercase text-xs border border-gray-400 border-b-0 p-2 mono bg-white hover:bg-k7green2"
        >
          History
        </Link>
      )
    } else {

    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    navigate(
      "/search#" + this.state.search,
      {
        state: {
          search: this.state.search,
        },
      }
    )
  }

  resultsEl() {

    let releasesSlice
    if (this.state.orderIsChronological) {
      releasesSlice = this.props.releases.slice(0, this.state.postsToShow)
    } else {
      releasesSlice = this.props.releases.reverse().slice(0, this.state.postsToShow)
    }

    let winWidth = typeof window !== `undefined` ? window.innerWidth : 1000
    let duration
    if (winWidth < 640) {
      duration = 80
    } else {
      duration = 112
    }

    return (
      <Controller>
        {releasesSlice.map( (node, index) => {
          return(
            <Scene duration={duration} classToggle="listEl-active" offset={0} indicators={false} key={index}>
              <div>
                <ReleaseListItem 
                  release={node}
                  i={index}
                  readyToLoad={this.props.readyToLoad}
                  releaseType={this.props.releaseType}
                />
              </div>
          </Scene>
          )
        })}
      </Controller>
    )

  }

  errorMessageEl() {

    return(
      <div className="p-8 uppercase w-full text-center mb-48">
        {this.props.errorMessage}
      </div>
    )

  }

  render() {
    let results, iconSrc
    if ( this.props.error == true ) {
      results = this.errorMessageEl()
    } else {
      results = this.resultsEl()
    }

    if (this.state.orderIsChronological) {
      iconSrc = upSort
    } else {
      iconSrc = downSort
    }

    return (
      <>
        <div className="mx-4 md:mx-6 lg:mx-6 xl:mx-6 overflow-hidden">
          <div className="float-left uppercase text-xs border border-gray-400 border-b-0 text-bold w-3/12 p-2 mono bg-white">
            {this.props.title}
          </div>
          <div className="float-left w-3/12">
            <form onSubmit={this.handleSubmit}>
              <img className="ml-3 mt-3 absolute cursor-none" src={iconSearch}/>
              <input 
                name="search" 
                className="pl-8 uppercase w-full text-xs border border-l-0 border-gray-400 border-b-0 text-bold p-2 mono bg-white" 
                type="text" 
                placeholder={this.props.searchQuery}
                value={this.state.firstName}
                onChange={this.handleInputChange}
              />
              <button 
                type="submit"
                style={{visibility: 'hidden'}}
              />

            </form>

          </div>

          {this.djKicksHistoryLink()}
        </div>
        <div className="w-full bg-white">
          <div className="w-full px-4 md:px-6 lg:px-6 xl:px-6 flex justify-center items-center gray-500 border-gray-400 border border-b-0 border-l-0 border-r-0 uppercase text-xs mono">
            <div className="hidden md:block lg:block xl:block p-1 px-2 w-1/12">
              Cat. 
            </div>
            <div className="p-1 px-2 w-3/12 text-center">

            </div>
            <div className="flex-grow p-1 px-2 w-7/12 flex">
              <div className="pl-3 md:pl-0 lg:pl-0 xl:pl-0 md:w-1/2 lg:w-1/2 xl:w-1/2 pr-0 md:pr-4 lg:pr-4 xl:pr-4">
                Title <span className="inline-block md:hidden lg:hidden xl:hidden">/</span>
              </div >
              <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 sm:gray-500">
                Artist
              </div>   
            </div>
            { this.yearColumn(iconSrc) }       
          </div>
        </div>

        <div className="border-t border-gray-400">
          {results}
        </div>     
      </>
    )
  }
}
export default ReleaseListContent

