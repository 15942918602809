import React, { Component } from "react"
import { graphql } from "gatsby"
import axios from "axios"
import ContextConsumer from "../services/context"
import ReleaseListContianer from "../components/releaseList/releaseListContainer"
import { getToken } from "../utils/getToken"


export const query = graphql`
  {
    allWordpressPage(filter: {slug: {eq: "dj-kicks-history"}}) {
      edges {
        node {
          id
          title
          slug
          content
        }
      }
    }
  }
`

class DjKicksHistory extends Component {
  state = {

  }

  postContent(content) {
    return <div dangerouslySetInnerHTML={{ __html: content }} />
  }

  render() {
    console.log(this.props)
    const post = this.props.data.allWordpressPage.edges[0].node
    const content = this.postContent(post.content)
    
    return (
      <>
        <div className="m-6">
          <div className="flex mb-6">
            <div className="w-9/12 md:w-7/12 lg:w-7/12 xl:w-7/12">
              <div className={`text-3xl md:text-4xl lg:text-4xl xl:text-4xl uppercase p-4 border border-gray-400 hover:bg-k7green block cursor pointer`}>
                History
              </div>
              <div className="content block w-2/3 p-6 border border-gray-400 border-t-0 text-sm">
                {content}
              </div>
            </div>
          </div>
        </div>
      </>

    )

  }

}

export default DjKicksHistory
