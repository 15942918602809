import React, { Component } from "react"
class DefaultImg extends Component {
  render() {
    return (
      <div style={{ width: this.props.width + 'px', height: this.props.height + 'px'}}>
        <svg className="w-full" viewBox="0 0 440 440" version="1.1" >
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Group-3" stroke="#979797">
                  <rect id="Rectangle-12" fill="#FFFFFF" x="0.5" y="0.5" width="439" height="439"></rect>
                  <path d="M0.5,439.5 L438.906204,1.09379566" id="Line" stroke-linecap="square"></path>
              </g>
          </g>
        </svg> 
      </div>
    )
  }
}

export default DefaultImg